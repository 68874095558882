<template>
    <div class="col-12">
        <template v-if="webPayDataError || notEnoughData">
            <!--Error-->
            <error></error>
            <p v-if="notEnoughData" class="alert alert-danger mt-5 text-center">{{$t('not-enough-data')}}</p>
            <p v-if="webPayDataError" class="alert alert-danger mt-5 text-center">{{webPayDataError}} {{$t('request-unsuccessful')}}</p>
        </template>
        <div v-else>
            <!--Success-->
            <form :action="webPayData.baseUrl" method="POST" ref="webPayForm">
                <template v-if="webPayData">
                    <input type="hidden" name="serviceId" :value="webPayData.serviceId">
                    <input type="hidden" name="orderId" :value="webPayData.orderId">
                    <input type="hidden" name="amount" :value="webPayData.amount">
                    <input type="hidden" name="currency" :value="webPayData.currency">
                    <input type="hidden" name="description" :value="webPayData.description">
                    <input type="hidden" name="email" :value="webPayData.email">
                    <input type="hidden" name="phone" :value="webPayData.phone">
                    <input type="hidden" name="lang" :value="webPayData.lang">
                    <input type="hidden" name="customFields" :value="webPayData.customFields">
                </template>
            </form>
        </div>
    </div>
</template>

<script>
    import {getWebPayData} from "../../../services/backend.service";

    export default {
        // This page is accessed with a transaction ID. A call is made with that transaction ID to get WebPay deposit data.
        // With that data we fill a hidden form on the page and submit it automatically.
        name: 'PaymentCenter',
        data(){
            return{
                notEnoughData: null,
                webPayDataError: null,
                webPayData: null,
            }
        },
        components:{
            Error: () => import('../../others/Error'),
        },
        mounted(){
            const transactionId = this.$router.history.current.query.transactionid;
            if(transactionId){
                getWebPayData(transactionId).then(res => {
                    if(res.data.success && res.data.data){
                        // ToDo: If possible set language from url parameter not from data (Changes from Marvin to add language as a url parameter)
                        this.$i18n.locale = res.data.data.lang;
                        this.webPayData = res.data.data;
                    } else {
                        this.webPayDataError = res.data.message && res.data.message !== "No message available" ? res.data.message : this.$t('error-2');
                    }
                }).catch( e => {
                    this.webPayDataError = this.$t('error-2');
                    console.log(`%c Error in PaymentCenter.vue - method: getWebPayData - ${e}`, 'color:red');
                });
            } else {
                this.notEnoughData = true;
            }
        },
        updated(){
            if(this.webPayData){
                this.submitForm();
            }
        },
        methods:{
            submitForm(){
                this.$refs.webPayForm.submit();
            }
        }
    }
</script>

<style scoped></style>
